import React from "react";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import * as IoIcons from "react-icons/io";
import * as GoIcons from "react-icons/go";
import * as RiIcons from "react-icons/ri";
import { DBConstants } from "./Constants";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { CoreComponent } from "./CoreComponent";
import { UserSession } from "./UserSession";

const SidebarModel = [
    {
        title: "Consents",
        path: "/main/consents",
        icon: <MdIcons.MdCreateNewFolder />,
        cName: "nav-text",
        user: true,
        corporate: false,
        admin: false
    },
    {
        title: "programs",
        path: "/main/programs",
        icon: <IoIcons.IoIosPaper />,
        cName: "nav-text",
        user: false,
        corporate: true,
        admin: false
    },
    {
        title: "Inquiries",
        path: "/main/inquires",
        icon: <MdIcons.MdCreateNewFolder />,
        cName: "nav-text",
        user: true,
        corporate: true,
        admin: false
    },
    {
        title: "Account",
        path: "/main/account",
        icon: <RiIcons.RiAccountCircleFill />,
        cName: "nav-text",
        user: true,
        corporate: true,
        admin: false
    },
    {
        title: "Support",
        path: "/main/support",
        icon: <IoIcons.IoMdHelpCircle />,
        cName: "nav-text",
        user: true,
        corporate: true,
        admin: false
    },
    {
        title: "Corporate List",
        path: "/main/corporatelist",
        icon: <GoIcons.GoVerified />,
        cName: "nav-text",
        user: false,
        corporate: false,
        admin: true
    },
    {
        title: "Users",
        path: "/main/userlist",
        icon: <GoIcons.GoVerified />,
        cName: "nav-text",
        user: false,
        corporate: false,
        admin: false
    },
    {
        title: "Add Corporate",
        path: "/main/addcorporate",
        icon: <RiIcons.RiAccountCircleFill />,
        cName: "nav-text",
        user: false,
        corporate: false,
        admin: true
    },
    {
        title: "Add program",
        path: "/main/addprogram",
        icon: <RiIcons.RiAccountCircleFill />,
        cName: "nav-text",
        user: false,
        corporate: false,
        admin: true
    },
    {
        title: "Logout",
        path: "/main/logout",
        icon: <MdIcons.MdKeyboardReturn />,
        cName: "nav-text",
        user: true,
        corporate: true,
        admin: true
    },
];

function displayMenu(item) {
    if (UserSession.isCorporate() && item.corporate)
        return true;
    else if (UserSession.isUser() && item.user)
        return true;
    else if (UserSession.isAdmin() && item.admin)
        return true;

    return false;

}

export class MobileNavBar extends CoreComponent {
    constructor() {
        super();
        this.state = { model: SidebarModel, show: false }
    }
    showSidebar = () => {

        if (this.state.show)
            this.setState({ show: false })
        else
            this.setState({ show: true })
    };

    render() {
        return (
            <div className="bggreen fcwhite">
                <div className="d-flex flex-row p15">
                    <Link to="#" className="ml10 fs25 fcwhite">
                        <FaIcons.FaBars onClick={this.showSidebar} />
                    </Link>
                    <div className=" fs25 ml20">
                        <span style={{ lineHeight: "45px" }} >trustedroads</span>
                    </div>
                </div>
                <nav
                    className={
                        this.state.show
                            ? "nav-menu-mobile active"
                            : "nav-menu-mobile"
                    }
                >
                    <ul className="nav-menu-items" onClick={this.showSidebar}>
                        <li >
                            <Link to="#" className="ml10 fs35 fcwhite">
                                <AiIcons.AiOutlineClose />
                            </Link>
                        </li>
                        {this.state.model.map((item, index) => {
                            if (displayMenu(item)) {
                                return (
                                    <li key={index} className={item.cName}>
                                        <Link to={item.path}>
                                            {item.icon}
                                            <span style={{ paddingLeft: "10px" }}>
                                                {item.title}
                                            </span>
                                        </Link>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </nav>
            </div>
        );
    }
}


export class SideBar extends CoreComponent {
    constructor() {
        super();
        this.state = { model: SidebarModel, "selected": "Consents" };
    }

    render() {
        return (
            <div className="bgsidebar fcwhite">
                <nav className="nav-menu active bgsidebar">
                    <ul className="nav-menu-items">
                        {this.state.model.map((item, index) => {
                            if (displayMenu(item)) {
                                return (
                                    <li key={index} className={item.cName}>
                                        <Link to={item.path}>
                                            {item.icon}
                                            {(item.title == this.state.selected) ?
                                                (<span className="pl10" style={{}}>{item.title}</span>) :
                                                (<span className="pl10">{item.title}</span>)
                                            }

                                        </Link>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </nav>
            </div>
        );
    }
}

export class TopBar extends CoreComponent {
    constructor() {
        super();
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center fcwhite bggreen p5 m0">
                    <div className="d-flex flex-row align-items-center">
                        <img className="mt5"
                            src={process.env.PUBLIC_URL + "/images/white-logo.png"}
                            width="50px"
                            height="45px"
                        />
                        <div className="fs25 ml10 mt5 fcwhite">
                            <span>TrustedRoads</span>
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center fs17 ml10 mt10 mr10 fcwhite">
                        <span>{UserSession.getIdentifier()}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export class BannerBar extends CoreComponent {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="d-flex flex-row align-items-center p5 bggreen">
                <img className="mt10"
                    src={process.env.PUBLIC_URL + "/images/white-logo.png"}
                    width="50px"
                    height="45px"
                />
                <div className="bggreen fs30 ml10 mt7 fcwhite">
                    <span>TrustedRoads</span>
                </div>
            </div>
        );
    }
}