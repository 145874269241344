import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getDateFromDict } from "../core/Util";
import { DBConstants } from "../core/Constants";
import { Link } from "react-router-dom";
import { Startup } from "../core/Util"
import { PROD } from "../core/Util"


export class ProgramView extends CoreComponent {

    constructor(props) {
        super();
        this.state.program = {}
    }

    componentDidMount() {
        if (UserSession.isLoggedIn()) {
            this.getdata();
        }
    }
    async getdata(datain) {

        var datain = {}

        datain[DBConstants.PROGRAM_ID] = this.props.match.params.programid
        if (UserSession.isUser())
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_USER
        else
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_CORPORATE
        this.showAnimation();
        let response = await Apiserver.postcall("get_program", datain);
        this.closeAnimation();


        let error = {};
        if (!Validator.checkResponseData(response, error)) {
            this.setState({ error: error });
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {

            let envForJs = Startup.ENV + ".js";
            if (Startup.ENV == PROD)
                envForJs = "js";
            let links = {
                "main": "https://content.trustedroads.com/" + Startup.ENV + "/" + response.data.corporateid + "/trustedroads." + response.data.programid + "." + envForJs,
                "submit": "https://content.trustedroads.com/" + Startup.ENV + "/" + response.data.corporateid + "/trustedroads_submitdata." + response.data.programid + "." + envForJs
            }

            this.setState({ program: response.data, links: links })
        }

    }



    async generatescript(programid, env) {

        var datain = {}
        datain[DBConstants.PROGARM_ID] = programid
        datain[DBConstants.ENV] = env
        if (UserSession.isUser())
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_USER
        else
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_CORPORATE

        this.showAnimation();
        let response = await Apiserver.postcall("gen_script", datain);
        this.closeAnimation();


        let error = {};
        if (!Validator.checkResponseData(response, error)) {
            this.setState({ error: error });
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
            this.redirectWithMessage(this.props, "/main/programs/programview/" + programid, "Script Generated!")
        }

    }

    onClickGenerateScript = (programid) => {
        this.generatescript(programid, Startup.ENV)
    };

    handleButtonClick = () => {

        this.props.history.goBack();
    }

    onClickViewprogramCertifcate = (corporateid, programid) => {
        let env = Startup.ENV + ".";
        console.log(env)
        if (env == "local.")
            env = "dev.";
        let url = "https://content.trustedroads.com/plugin/popupshell.html?ccpopupcorporateid=" + corporateid + "&ccpopupprogramid=" + programid + "&ccenv=" + env;
        window.open(url, '_blank').focus();
    };

    render() {
        return (
            <div className="col p0 m0 mw700">

                {this.getMessagePanel()}
                <div className="container mw99p mt10 ml0 mr10 mb0 pt0 pl0 pr0 pb10 bglightgray">
                    <div className="row m0 pt5 pl0 pr0 pb0">
                        <div className="col-12"><span className="propertyname">Program Details:</span></div>
                    </div>

                    <div className="row m0 pt5 pl0 pr0 pb0">
                        <div className="col-12"><span className="propertyvalue"> Id: {this.state.program.programid && this.state.program.programid}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Program Name: {this.state.program.programname && this.state.program.programname}</span></div>
                        <div className="col-12"><span className="propertyvalue"><a href={this.state.program.termlink} target="_blank"><u>Program Terms Link</u></a></span></div>
                    </div>

                    <div className="row m0 pt5 pl0 pr0 pb0">
                        <div className="col-12"><span className="propertyname">Opt-out email/link</span></div>
                        <div className="col-12"><span className=" propertyvalue prewrap">{this.state.program.dsrinfo && this.state.program.dsrinfo}</span></div>
                    </div>

                    <div className="row m0 pt5 pl0 pr0 pb0">
                        <div className="col-12"><span className="propertyname">Reputation Details:</span></div>
                    </div>
                    <div className="row m0 pt5 pl0 pr0 pb0">
                        <div className="col-12"><span className="propertyvalue"> Rating Stars: {this.state.program.startrating && this.state.program.startrating}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Total Consents : {this.state.program.totalconsents && this.state.program.totalconsents}</span></div>
                        <div className="col-12"><span className="propertyvalue"> New Issues: {this.state.program.newissues && this.state.program.newissues}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Pending Issues : {this.state.program.pendingissues && this.state.program.pendingissues}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Closed Issues: {this.state.program.closedissues && this.state.program.closedissues}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Resolved Issues : {this.state.program.resolvedissues && this.state.program.resolvedissues}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Valid Issues : {this.state.program.validissues && this.state.program.validissues}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Valid Issues (last 12 months) : {this.state.program.validissuesbytime && this.state.program.validissuesbytime}</span></div>
                        <div className="col-12"><span style={{ color: "blue", cursor: "pointer" }} onClick={() => this.onClickViewprogramCertifcate(this.state.program.corporateid, this.state.program.programid)} className="propertyvalue"><u>Program Reputation</u></span></div>

                    </div>
                </div>


                <div className="row hc vc mt10 ml10 mr10">
                    <button
                        onClick={this.handleButtonClick}
                        type="button"
                        className=" btn btn-primary">
                        Back
                        </button>
                </div>

            </div >

        );
    }
}

/*
{UserSession.isAdmin() &&
                        <div className="row m0 pt5 pl0 pr0 pb5">
                            <div className="col-12"><span style={{ color: "blue", cursor: "pointer" }} className=" propertyvalue clickablelink" onClick={() => this.onClickGenerateScript(this.state.program.programid)}>Generate Script</span></div>
                        </div>
                    }
                    {this.state.links &&

                        <div className="row m0 pt5 pl0 pr0 pb0">
                            <div className="col-12"><span className="propertyname">Script Links:</span></div>
                            <div className="col-12">
                                <div><span className="propertyvalue"><a href={this.state.links.main} target="_blank"><u>Main script</u></a></span></div>
                                <div><span className="propertyvalue"><a href={this.state.links.submit} target="_blank"><u>Submit script</u></a></span></div>
                            </div>
                        </div>
                    }
*/