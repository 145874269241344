import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { log, Validator, getValue } from "../core/Util";
import { Apiserver } from "../core/Apiserver";
import { DBConstants } from "../core/Constants"
import { UserSession } from "../core/UserSession";
import { BannerBar } from "../core/MenuBar";


export class ForgotPwd extends CoreComponent {

  constructor() {
    super();
  }

  handleButtonClick = () => {

    let error = {};
    Validator.checkEmailId(error, "email");

    if (Validator.dict_size(error) != 0) {
      this.setState({ error: error })

    } else

      this.postdata()
  }

  handlecancelButtonClick = () => {
    this.redirect(this.props, '/login')
  }

  async postdata() {

    let datain = {}
    let emailid = getValue("email")
    datain[DBConstants.Email_ID] = emailid
    datain[DBConstants.SIGNUP_LINK_TYPE] = DBConstants.FORGOT_PWD_LINK
    UserSession.emailid = emailid

    this.showAnimation();
    let response = await Apiserver.postcall("send_signup_link", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.redirectWithMessageAndData(this.props, "/setuppwd", "Password Reset Successfully!", datain)
    }

  }

  render() {
    return (
      <div className="d-flex flex-row m0 p0 hfull backimg justify-content-center">
        <div className="col col-lg-5 col-md-6 col-s-12 fs15 m20 mt30 p0 formborder justify-content-center mh500">
          <BannerBar />
          <div>
            <label htmlFor="username" className="row p0 m0 mt25 ml10 propertytitle form-label">
              Forgot Password
        </label>
          </div>
          <div className="mt20 ml10 mr10">

            {this.getMessagePanel()}

            <label htmlFor="username" className="form-label prewrap">
              Your password will be reset and a link to re-enter a new password will be send to your email id. <br />
            </label>
            <label htmlFor="username" className="propertyname form-label">
              Please enter your email id:
            </label>
            {this.state.error.email && (
              <div className="alert alert-danger">{this.state.error.email}</div>
            )}
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="name@example.com"
              size="5"
            />
          </div>
          <div className="d-flex flex-row hc vc mt20 ml10 mr10">
            <button
              onClick={this.handleButtonClick}
              type="button"
              className=" btn btn-primary"
            >
              Reset My Password
            </button>
            <button
              onClick={this.handlecancelButtonClick}
              type="button"
              className=" ml10 btn btn-primary"
            >
              Cancel
          </button>
          </div>
        </div>
      </div>
    );
  }
}
