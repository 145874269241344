import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getValue, setValue } from "../core/Util";
import { DBConstants, ErrorMsg } from "../core/Constants";


export class AddProgram extends CoreComponent {

  constructor() {
    super();
    UserSession.setToAdmin()
  }

  handleButtonClick = () => {

    let error = {};
    Validator.checkValidString(error, "id");
    Validator.checkValidString(error, "name");
    Validator.checkValidString(error, "termlink");
    Validator.checkValidString(error, "dsrinfo");

    if (Validator.dict_size(error) != 0) {
      this.setState({ error: error })

    } else
      this.postdata()
  };

  async postdata() {

    let datain = {}
    datain[DBConstants.Corporate_ID] = getValue("id")
    datain[DBConstants.PROGARM_NAME] = getValue("name")
    datain[DBConstants.PROGARM_TERM_LINK] = getValue("termlink")
    datain[DBConstants.PROGRAM_DSR_INFO] = getValue("dsrinfo")


    this.showAnimation();
    let response = await Apiserver.postcall("add_program", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setValue("id")
      setValue("name")
      setValue("termlink")
      setValue("dsrinfo")
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.redirectWithMessage(this.props, "/main/addprogram", "program Added!")
    }

  }

  render() {
    return (
      <div className="d-flex flex-row mt5 pl10 pr10 ">
        <div className="container col-md-6 col-s-12 fs15 m0 pl0 pr0 pt0 pb10 mw500">

          <div className="mt20 ml10 mr10">
            {this.getMessagePanel()}

            <label className="form-label">Corporate Id:</label>
            {this.state.error.id && (
              <div className="alert alert-danger mt10">{this.state.error.id}</div>
            )}
            <input className="form-control" id="id" size="5" />

            <label className="form-label mt10">Program Name:</label>
            {this.state.error.name && (
              <div className="alert alert-danger mt10">{this.state.error.name}</div>
            )}
            <input className="form-control" id="name" size="5" />

            <label className="form-label mt10">Program Terms Link:</label>
            {this.state.error.termlink && (
              <div className="alert alert-danger mt10">{this.state.error.termlink}</div>
            )}
            <input className="form-control" id="termlink" size="5" />

            <label className="form-label mt10">Program DSR Link/Email:</label>
            {this.state.error.dsrinfo && (
              <div className="alert alert-danger mt10">{this.state.error.dsrinfo}</div>
            )}
            <input className="form-control" id="dsrinfo" size="5" />

          </div>
          <div className="d-flex flex-row hc vc mt20 ml10 mr10">
            <button
              onClick={this.handleButtonClick}
              type="button"
              className=" btn btn-primary"
            >
              Add program
            </button>
          </div>
        </div>
      </div>
    );
  }
}


