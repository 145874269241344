import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getDateFromDict } from "../core/Util";
import { Link } from "react-router-dom";
import { DBConstants } from "../core/Constants";
import * as MdIcons from "react-icons/md";

export class Programs extends CoreComponent {

  constructor() {
    super();
    this.state.model = []
  }

  componentDidMount() {
    this.addDeviceTypeListener()
    if (UserSession.isLoggedIn()) {
      this.getdata();
    }
  }

  async getdata() {
    var datain = {}
    this.showAnimation();
    let response = await Apiserver.postcall("get_program_list", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      if (response.data.list.length == 0)
        this.setState({ model: response.data.list, "displaymessage": "You don't have any programs" })
      else
        this.setState({ model: response.data.list })
    }
  }

  onClickView = (key) => {
    this.props.history.push("/main/programs/programview/" + key);
  };

  getDesktopRender() {
    return (
      <div className="container m0 p0">
        {this.getMessagePanel()}
        <div className="d-flex flex-row-reverse mt10 ml10 mr10 mb10" onClick={this.refresh}>
          <button onClick={this.refresh} type="button" className=" btn btn-primary">Refresh</button>
        </div>
        <table className="col-12 fs17 table">
          <thead className="bgtable">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Date Created</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.model.map((item, index) => (
              <tr key={index}>
                <td>{item.programname}</td>
                <td>{getDateFromDict(item.date)}</td>
                <td className="m0 p0">
                  <div className="d-flex flex-row m0 p0" style={{ color: "blue", cursor: "pointer" }}>
                    <span onClick={() => this.onClickView(item.programid)} ><u>View</u></span>
                    {/*<div onClick={() => this.onClickView(item.programid)} className="icon ml10 mt0 mb0 mr0 p0" data-toggle="tooltip" data-placement="bottom" title="View">
                      <MdIcons.MdPageview />
                    </div>*/}

                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  refresh = () => {
    this.reload()
  };

  getMobileRender() {
    return (
      <div className="col p0 m0 mw700">
        {this.getMessagePanel()}
        <div className="d-flex flex-row-reverse mt10 ml10 mr10 mb10" onClick={this.refresh}>
          <button onClick={this.refresh} type="button" className=" btn btn-primary">Refresh</button>
        </div>
        {this.state.model.map((item, index) => (
          <div key={index} className="container mw99p mt10 ml0 mr10 mb0 p0 bglightgray">
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-12"><span className="propertyname">Name:</span></div>
              <div className="col-12"><span className="propertyvalue">{item.programname}</span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-12"><span className="propertyname">Date Created:</span></div>
              <div className="col-12"><span className="propertyvalue">{getDateFromDict(item.date)}</span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="d-flex flex-row" style={{ color: "blue", cursor: "pointer" }}>
                <span onClick={() => this.onClickView(item.programid)} ><u>View</u></span>
                {/*<div onClick={() => this.onClickView(item.programid)} className="icon ml10"
                  data-toggle="tooltip" data-placement="bottom" title="View">
                  <MdIcons.MdPageview />
                </div>*/}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
  render() {
    return (<>{this.isDesktop ? this.getDesktopRender() : this.getMobileRender()}</>);
  }
}

