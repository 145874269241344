import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getDateFromDict, Startup } from "../core/Util";

export class Account extends CoreComponent {

  constructor() {
    super();
    this.state.model = []

  }

  componentDidMount() {

    if (UserSession.isLoggedIn()) {
      this.getaccountdata();
      //this.getlinksdata();
    }

  }

  async getaccountdata() {
    var datain = {}

    this.showAnimation();
    let response = await Apiserver.postcall("get_account", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      //let list = this.mappingData(response.data.list)
      this.setState({ model: response.data })
    }
  }

  async getlinksdata() {
    var datain = {}

    this.showAnimation();
    let response = await Apiserver.postcall("getLinks", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      //let list = this.mappingData(response.data.list)
      //this.setState({ model: response.data })
      //console.log(response.data)
    }
  }

  changepwd = () => {
    this.props.history.push("/main/account/changepwd");
  }

  edit = () => {
    this.props.history.push("/main/account/editaccountinfo");
  }

  getAddress(dict) {
    return dict.street + " , " + dict.city + " , " + dict.zip + " , " + dict.country
  }

  render() {
    return (
      <div className="col p0 m0 mw700">
        {this.getMessagePanel()}

        <div className="row hr vr mt10 ml10 mr10">
        {/*<button
            onClick={this.edit}
            type="button"
            className=" btn btn-primary mr10">
            Edit
        </button>*/}
          <button
            onClick={this.changepwd}
            type="button"
            className=" btn btn-primary">
            Change Password
          </button>
        </div>
        <div className="container mw99p mt10 ml0 mr10 mb0 pt0 pl0 pr0 pb10 bglightgray">
          <div className="row m0 pt5 pl0 pr0 pb0">
            <div className="col-md-3 col-s-12"><span className="propertyname">Account Id:</span></div>
            <div className="col-md-9 col-s-12"><span className="propertyvalue">{UserSession.isUser()?(this.state.model.userid):(this.state.model.corporateid)}</span></div>
          </div>
          <div className="row m0 pt5 pl0 pr0 pb0">
            <div className="col-md-3 col-s-12"><span className="propertyname">Date Created:</span></div>
            <div className="col-md-9 col-s-12"><span className="propertyvalue">{this.state.model.date && getDateFromDict(this.state.model.date)}</span></div>
          </div>
          <div className="row m0 pt5 pl0 pr0 pb0">
            <div className="col-md-3 col-s-12"><span className="propertyname">Email:</span></div>
            <div className="col-md-9 col-s-12"><span className=" propertyvalue">{this.state.model.emailid && this.state.model.emailid}</span></div>
          </div>

          {/*UserSession.isUser() && (<>
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-md-3 col-s-12"><span className="propertyname">First Name:</span></div>
              <div className="col-md-9 col-s-12"><span className="propertyvalue"></span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-md-3 col-s-12"><span className="propertyname">Last Name:</span></div>
              <div className="col-md-9 col-s-12"><span className=" propertyvalue"></span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-md-3 col-s-12"><span className="propertyname">Phone:</span></div>
              <div className="col-md-9 col-s-12"><span className=" propertyvalue"></span></div>
            </div>
          </>)*/}

          {!UserSession.isUser() && (<>
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-md-3 col-s-12"><span className="propertyname">Corporate Name:</span></div>
              <div className="col-md-9 col-s-12"><span className="propertyvalue">{this.state.model.corporatename && this.state.model.corporatename}</span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-md-3 col-s-12"><span className="propertyname">Website:</span></div>
              <div className="col-md-9 col-s-12"><span className=" propertyvalue">{this.state.model.website && this.state.model.website}</span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-md-3 col-s-12"><span className="propertyname">Secondary Email:</span></div>
              <div className="col-md-9 col-s-12"><span className=" propertyvalue">{this.state.model.secondaryemailid && this.state.model.secondaryemailid}</span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb5">
              <div className="col-md-3 col-s-12"><span className="propertyname">Billing Email:</span></div>
              <div className="col-md-9 col-s-12"><span className=" propertyvalue"> {this.state.model.billingemailid && this.state.model.billingemailid}</span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb5">
              <div className="col-md-3 col-s-12"><span className="propertyname">Phone:</span></div>
              <div className="col-md-9 col-s-12"><span className=" propertyvalue"> {this.state.model.phone && this.state.model.phone}</span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb5">
              <div className="col-md-3 col-s-12"><span className="propertyname">Billing Phone:</span></div>
              <div className="col-md-9 col-s-12"><span className=" propertyvalue"> {this.state.model.billingphone && this.state.model.billingphone}</span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb5">
              <div className="col-md-3 col-s-12"><span className="propertyname">Address:</span></div>
              <div className="col-md-9 col-s-12"><span className=" propertyvalue"> {this.getAddress(this.state.model)}</span></div>
            </div>


          </>)}
        </div>
      </div>
    );
  }
}
