import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Programs } from "./Programs";
import { Support } from "./Support";
import { Account } from "./Account";
import { UserSession } from "../core/UserSession";
import { SideBar } from "../core/MenuBar";
import { MobileNavBar } from "../core/MenuBar";
import { TopBar } from "../core/MenuBar";
import { CoreComponent } from "../core/CoreComponent";
import { Consent } from "./Consent";
import { Logout } from "./Logout";
import { AddCorporate } from "./AddCorporate";
import { AddProgram } from "./AddProgram";
import { ChangePwd } from "./ChangePwd";
import { EditAccountInfo } from "./EditAccountInfo";
import { CorporateList } from "./CorporateList";
import { ProgramView } from "./ProgramView";
import { ConsentView } from "./ConsentView";
import { Inquiry } from "./Inquiry";
import { InquiryView } from "./InquiryView";


export class Main extends CoreComponent {

  constructor(props) {
    super(props);
    if (!UserSession.isLoggedIn()) {
      this.redirectWithFullPath(props, "/login", props.location.pathname);
    }
  }

  componentDidMount() {
    this.addDeviceTypeListener()

  }

  getMainContentData() {
    return (
      <div className="container m0 container-p mw99p">
        <Route path="/main/consents" exact component={Consent} />
        <Route path="/main/consents/consentview/:consentid" exact component={ConsentView} />
        <Route path="/main/inquires" exact component={Inquiry} />
        <Route path="/main/inquires/inquiryview/:inquiryid" exact component={InquiryView} />
        <Route path="/main/consents/userconsentview/:consentid" exact component={ConsentView} />
        <Route path="/main/consents/userconsentviewlink/:enc_consentid" exact component={ConsentView} />
        <Route path="/main/programs" exact component={Programs} />
        <Route path="/main/programs/programview/:programid?" exact component={ProgramView} />
        <Route path="/main/account" exact component={Account} />
        <Route path="/main/account/changepwd" exact component={ChangePwd} />
        <Route path="/main/account/editaccountinfo" exact component={EditAccountInfo} />
        <Route path="/main/support" exact component={Support} />
        <Route path="/main/addcorporate" exact component={AddCorporate} />
        <Route path="/main/corporatelist" exact component={CorporateList} />
        <Route path="/main/addprogram" exact component={AddProgram} />
        <Route path="/main/logout" exact component={Logout} />
      </div>
    );
  }

  render() {
    return (
      <div>
        {
          !this.state.isDesktop ? (
            <div>
              <MobileNavBar ref={ref => (UserSession.mobileBarRef = ref)} />
              {this.getMainContentData()}
            </div>
          ) : (
            <div>
              <TopBar ref={ref => (UserSession.topBarRef = ref)} />
              <div className="d-flex">
                <SideBar ref={ref => (UserSession.sideBarRef = ref)} />
                {this.getMainContentData()}
              </div>
            </div>
          )}
      </div>
    );
  }
}
