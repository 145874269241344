import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { log, addData, Validator, setValue, getValue } from "../core/Util";
import { DBConstants } from "../core/Constants";
import { Apiserver } from "../core/Apiserver";

export class Support extends CoreComponent {

  constructor() {
    super();

  }

  handleButtonClick = () => {

    //window.location.href = "mailto:support@trustedroads.com";

    let error = {};

    Validator.checkValidString(error, "subject");
    Validator.checkValidString(error, "message");

    if (Validator.dict_size(error) != 0) {
      this.setState({ error: error })

    } else {
      this.postdata()
    }

  }

  async postdata() {

    let datain = {};
    datain[DBConstants.SUPPORT_SUBJECT] = getValue("subject");
    datain[DBConstants.SUPPORT_MESSAGE] = getValue("message");

    this.showAnimation();
    let response = await Apiserver.postcall("post_support", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setValue("subject", "");
      setValue("message", "");
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.redirectWithMessage(this.props, "/main/support", "Support Message Sent, trustedroads.com will contact you shortly")
    }

  }

  render_old() {
    return (
      <div>
        <div>If you have any issue using the product please email  support@trustedroads.com</div>
        <div className="row mt20 ml10 mr10">
          <button
            onClick={this.handleButtonClick}
            type="button"
            className=" btn btn-primary">
            Email Support
            </button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="col p0 m0 mw500">
        {this.getMessagePanel()}

        {this.state.error.subject && (
          <div className="alert alert-danger">{this.state.error.subject}</div>
        )}
        <div className="row m0 pt5 pl0 pr0 pb10">

          <div className="col-md-4 col-s-12"><span className="propertyname fs17">Subject:</span></div>
          <div className="col-md-8 col-s-12">
            <input
              className="form-control"
              id="subject"
              rows="6"></input>
          </div>
        </div>

        {this.state.error.message && (
          <div className="alert alert-danger">{this.state.error.message}</div>
        )}
        <div className="row m0 pt15 pl0 pr0 pb0">
          <div className="col-md-4 col-s-12"><span className="propertyname fs17">Message:</span></div>
          <div className="col-md-8 col-s-12">
            <textarea
              className="form-control"
              id="message"
              rows="6"></textarea>
          </div>
        </div>

        <div className="row hc vc mt20 ml10 mr10">
          <button
            onClick={this.handleButtonClick}
            type="button"
            className=" btn btn-primary">
            Submit
            </button>
        </div>

      </div>
    );
  }
}
