import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getDateFromDict } from "../core/Util";
import { DBConstants } from "../core/Constants";
import { Link } from "react-router-dom";


export class InquiryView extends CoreComponent {

    constructor(props) {
        super();
        this.state.consent = {}
    }

    componentDidMount() {
        if (UserSession.isLoggedIn()) {
            this.getdata();
        }
    }
    async getdata() {
        let datain = {}

        datain[DBConstants.INQUIRY_ID] = this.props.match.params.inquiryid

        if (UserSession.isUser())
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_USER
        else
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_CORPORATE

        this.showAnimation();
        let response = await Apiserver.postcall("get_inquiry", datain);
        this.closeAnimation();


        let error = {};
        if (!Validator.checkResponseData(response, error)) {
            this.setState({ error: error });
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
            this.setState({ inquiry: response.data })
        }

    }

    handleButtonClick = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="col p0 m0 mw700">

                {this.getMessagePanel()}
                <div className="container mw99p mt10 ml0 mr10 mb0 pt0 pl0 pr0 pb10 bglightgray">
                    <div className="row m0 pt5 pl0 pr0 pb0">
                        <div className="col-12"><span className="propertyname fcmaroon">Inquiry Details</span></div>
                    </div>
                    {this.state.inquiry && <div>
                        <div className="row m0 pt5 pl0 pr0 pb0">
                            <div className="col-12"><span className="propertyname">Inquiry Id : </span><span className="propertyvalue">{this.state.inquiry.inquiryid}</span></div>
                            <div className="col-12"><span className="propertyname">Program Name :</span><span className="propertyvalue">{this.state.inquiry.programname}</span></div>
                            <div className="col-12"><span className="propertyname">Program Id : </span><span className="propertyvalue">{this.state.inquiry.programid}</span></div>
                            <div className="col-12"><span className="propertyname">Date Created : </span><span className="propertyvalue">{getDateFromDict(this.state.inquiry.date)}</span></div>
                            <div className="col-12"><span className="propertyname">Status : </span><span className="propertyvalue">{this.state.inquiry.status}</span></div>
                            <div className="col-12"><span className="propertyname">User Email : </span><span className="propertyvalue">{this.state.inquiry.emailid}</span></div>
                            <div className="col-12"><span className="propertyname">Ack : </span><span className="propertyvalue">{this.state.inquiry.ack.toString()}</span></div>
                        </div>

                        <div className="row m0 pt5 pl0 pr0 pb0">
                            <div className="col-12"><span className="propertyname">Type: </span><span className="propertyvalue">{this.state.inquiry.type}</span></div>
                            <div className="col-12"><span className="propertyname">Resident Of: </span><span className="propertyvalue">{this.state.inquiry.residentof}</span></div>
                            <div className="col-12"><span className="propertyname">More Info: </span><span className="propertyvalue">{this.state.inquiry.moreinfo}</span></div>
                        </div>

                    </div>}

                </div>

                <div className="row hc vc mt10 ml10 mr10">
                    <button
                        onClick={this.handleButtonClick}
                        type="button"
                        className=" btn btn-primary">
                        Back
                        </button>
                </div>

            </div >

        );
    }
}

