import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getValue } from "../core/Util";
import { DBConstants, ErrorMsg } from "../core/Constants";

export class EditAccountInfo extends CoreComponent {

  constructor() {
    super();
  }

  cancel = () => {
    this.redirect(this.props,'/main/account/')
  }

  save = () => {

    let error = {};
    Validator.checkValidString(error, "fname");
    Validator.checkValidString(error, "lname");
    Validator.checkValidString(error, "phone");

    if (Validator.dict_size(error) != 0) {
      this.setState({ error: error })
    } else{
      //this.postdata()
    }
  };

  async postdata() {

    let datain = {}
    datain[DBConstants.FIRST_NAME] = getValue("fname")
    datain[DBConstants.LAST_NAME] = getValue("lname")
    datain[DBConstants.PHONE] = getValue("phone")

    this.showAnimation();
    let response = await Apiserver.postcall("edit_accountinfo", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.redirectWithMessage(this.props,"/main/account","Account Info Edited!")
    }

  }

  render() {
    return (
      <div className="d-flex flex-row mt20 pl10 pr10 ">
        <div className="container col-md-6 col-s-12 fs15 m0 pl0 pr0 pt0 pb10 mw500">

          <div className="mt20 ml10 mr10">
            {this.getMessagePanel()}

            {this.state.error.password && (
              <div className="alert alert-danger mt10">{this.state.error.password}</div>
            )}
            <label htmlFor="username" className="form-label">
              First Name:
            </label>

            <input
              className="form-control"
              id="fname"
              size="5"
            />
            {this.state.error.password1 && (
              <div className="alert alert-danger mt10">{this.state.error.password1}</div>
            )}
            <label htmlFor="username" className="form-label mt10">
              Last Name:
            </label>
            <input
              className="form-control mt10"
              id="lname"
              size="5"
            />
            {this.state.error.password2 && (
              <div className="alert alert-danger mt10">{this.state.error.password2}</div>
            )}
            <label htmlFor="username" className="form-label mt10">
              Phone:
            </label>
            <input
              className="form-control mt10"
              id="phone"
              size="5"
            />
          </div>
          <div className="d-flex flex-row hc vc mt20 ml10 mr10">
            <button
              onClick={this.save}
              type="button"
              className=" btn btn-primary mr10"
            >
              Save
            </button>

            <button
              onClick={this.cancel}
              type="button"
              className=" btn btn-primary"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}
